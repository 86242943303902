import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Espècie molt semblant a A.ovoidea però més petita i quasi mai presenta restes de vel sobre el capell. L’anell és més persistent i estriat. La volva de color ocre està molt aferrada al peu. Les espores són blanques en massa, d’el·lipsoidals a oblongues de 8-11 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      